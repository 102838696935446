import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { ChangeTypesComponent } from './components/admin/change-types/change-types.component';
import { DosagesComponent } from './components/admin/dosages/dosages.component';
import { ClientsComponent } from './components/admin/clients/clients.component';
import { UsersComponent } from './components/admin/users/users.component';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { PaymentComponent } from './components/admin/payment/payment.component';
import { SearchPaymentComponent } from './components/admin/search-payment/search-payment.component';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import { ModalModule } from 'ngx-bootstrap';
import { CompanyComponent } from './components/admin/company/company.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { InvoiceComponent } from './components/admin/invoice/invoice.component';
// import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ChangeTypesComponent,
    DosagesComponent,
    ClientsComponent,
    UsersComponent,
    NavbarComponent,
    PaymentComponent,
    SearchPaymentComponent,
    CompanyComponent,
    InvoiceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    // SweetAlert2Module.forRoot(),
    TypeaheadModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    NgbModule
  ],
  entryComponents: [
    InvoiceComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
