import { Component, OnInit, TemplateRef } from '@angular/core';
import { DosagesService } from 'src/app/service/dosages.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthorizationService } from 'src/app/service/authorization.service';
import swal from 'sweetalert2';


@Component({
  selector: 'app-dosages',
  templateUrl: './dosages.component.html',
  styleUrls: ['./dosages.component.scss']
})
export class DosagesComponent implements OnInit {

  public modalRef: BsModalRef;
  public submittedOption: any = false;
  submitted: any = false;

  public listDosages: any = [];
  dosageFormGroup: FormGroup;
  dosage: any = {};
  currentPage = 1;
  pageSize = 10;

  constructor(
    private dosagesServices: DosagesService,
    private modalService: BsModalService,
    private authService: AuthorizationService,
    private formBuilder: FormBuilder
  ) {
    this.validateFormGroup();
  }

  ngOnInit() {
    this.getAllDosages();
  }

  getAllDosages() {
    this.dosagesServices.getAll(this.currentPage - 1, this.pageSize).subscribe(
      (res) => {
        //console.log(res);
        this.listDosages = res;
      }
    );
  }

  validateFormGroup() {
    this.dosageFormGroup = this.formBuilder.group({
      id: [null],
      startValue: ['', [Validators.required]],
      endValue: ['', [Validators.required]],
      name: ['', [Validators.required]],
      authorizationNumber: ['', [Validators.required]],
      dosageKey: ['', [Validators.required]],
      emissionDeadline: ['', [Validators.required]],
      legend: [''],
      dosageType: ['INVOICE'],
      invoiceType: ['COMPUTERIZED'],
      status: [true]
    });
  }

  editDosage(dosage) {
    console.log(dosage);
  }

  openModal(template: TemplateRef<any>, dosificacion?: any) {
    //console.log(dosificacion);
    this.modalRef = this.modalService.show(template);
    if (dosificacion) {
      this.submitted = false;
      this.submittedOption = false;
      this.dosageFormGroup.patchValue(dosificacion);
    } else {
      this.submitted = false;
      this.submittedOption = false;
      this.validateFormGroup();
    }
  }

  openModalDelete(template: TemplateRef<any>, dosificacion?: any) {
    this.dosage = dosificacion;
    this.modalRef = this.modalService.show(template);
  }

  deleteDosage() {
    this.dosagesServices.deleteDosa(this.dosage.id).subscribe(
      (res) => {
        this.getAllDosages();
        this.modalRef.hide();
      }
    );
  }

  onSubmit() {
    this.submitted = true;
    if (this.dosageFormGroup.invalid) {
      return;
    }

    if (this.formValue.id) {
      this.update();
    } else {
      if (!this.submittedOption) {
        this.submittedOption = true;
        // console.log("ingresando dosifica");

        this.create();
      }
    }
  }

  update() {
    this.dosagesServices.update(this.dosageFormGroup.value).subscribe(
      (res) => {
        this.validateFormGroup();
        this.modalRef.hide();
        this.getAllDosages();
      }
    );
  }

  create() {
    // console.log(this.dosageFormGroup.value);
    this.dosagesServices.create(this.dosageFormGroup.value).subscribe(
      (res) => {
        this.validateFormGroup();
        this.modalRef.hide();
        this.getAllDosages();
      }
    );
  }

  get formValue() {
    return this.dosageFormGroup.value;
  }

  get formControls() {
    return this.dosageFormGroup.controls;
  }

  closeDosage() {}
}
