import { Injectable } from '@angular/core';
import { WebService } from './web.service';
import { SERVER } from '../config/server.config';
import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private webService: WebService,
    private authService: AuthorizationService
  ) {
  }

  pageSize(page, size, option): string {
    return option + 'page=' + page + '&size=' + size;
  }

  getAll(page, size) {
    const URL = SERVER.PAYMENT + '/' + this.pageSize(page, size, '?');
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.get(URL, headers);
  }

  createPayment(payment: any) {
    const URL = SERVER.PAYMENT + '/save-payment-with-detail';
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.post(URL, payment, headers);
  }

  search(keyword: any, startDate: any, endDate: any, state: any, page, size) {
    const URL = SERVER.PAYMENT + '/search?keyword=' + keyword + '&startDate=' +
      startDate + '&endDate=' + endDate + '&state=' + state + this.pageSize(page, size, '&');
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.get(URL, headers);
  }

  cancelPayment(payment: any) {
    const URL = SERVER.PAYMENT + '/cancel-payment';
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.post(URL, payment, headers);
  }

  getInvoiceByPaymentId(paymentId: any) {
    const URL = SERVER.PAYMENT + '/get-invoice-by-paymentid/' + paymentId;
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.get(URL, headers);
  }

  export(keyword: any, startDate: any, endDate: any, state: any) {
    const URL = SERVER.PAYMENT + '/list_export?keyword=' + keyword + '&startDate=' +
      startDate + '&endDate=' + endDate + '&state=' + state;
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.get(URL, headers);
  }
}
