import { Injectable } from '@angular/core';
import { WebService } from './web.service';
import { SERVER } from '../config/server.config';
import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentDetailService {

  constructor(
    private webService: WebService,
    private authService: AuthorizationService
  ) { }

  getDetailByPaymentId(paymentId: any) {
    const URL = SERVER.PAYMENTDETAIL + '/by-paymentId/' + paymentId;
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.get(URL, headers);
  }
}
