import { Component } from '@angular/core';
import { Router } from '@angular/router';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'easypay-frontend';

  constructor(
    private router: Router
  ) {}

  hiddenNavbar() {
    return ((this.router.url !== '/login'));
  }
}
