import { Injectable } from '@angular/core';
import { WebService } from './web.service';
import { SERVER } from '../config/server.config';
import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private webService: WebService,
    private authService: AuthorizationService
  ) { }

  getAll(page, size) {
    const URL = SERVER.USERS + '/' + this.pageSize(page, size);
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.get(URL, headers);
  }

  pageSize(page, size): string {
    return '?page=' + page + '&size=' + size;
  }

  getId(id: any) {
    const URL = SERVER.USERS + '/' + id;
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.get(URL, headers);
  }

  create(user: any) {
    const URL = SERVER.USERS + '/add';
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.post(URL, user, headers);
  }

  delete(userId: any) {
    const URL = SERVER.USERS + '/' + userId;
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.delete(URL, headers);
  }

  update(user: any) {
    const URL = SERVER.USERS + '/update';
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.put(URL, user, headers);
  }

  getCurrentUser() {
    const URL = SERVER.USERS + '/current';
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.get(URL, headers);
  }
}
