import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateFunctionService {

  constructor() { }

  convertirFechaATexto(fecha: any) {
    console.log(fecha);
    // const options = { year: 'numeric', month: 'long', day: 'numeric' };
    // return fecha.toLocaleDateString('es-ES', options);
    const meses = [
      'Enero', 'Febrero', 'Marzo',
      'Abril', 'Mayo', 'Junio', 'Julio',
      'Agosto', 'Septiembre', 'Octubre',
      'Noviembre', 'Diciembre'
    ];
    const date2 = fecha.split('-');

    const date = new Date(date2[0], (date2[1] - 1), date2[2]);
    console.log('fecha ' + date);
    const dia = date.getDate();
    const mes = date.getMonth();
    const ano = date.getFullYear();
    return `${dia} de ${meses[mes]} de ${ano}`;
  }

  convertirFechaDDMMYYYY(fecha: any) {
    const date2 = fecha.split('-');
    return `${date2[2]}/${date2[1]}/${date2[0]}`;
  }
}
