import { Route, RouterModule } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { ChangeTypesComponent } from './components/admin/change-types/change-types.component';
import { DosagesComponent } from './components/admin/dosages/dosages.component';
import { ClientsComponent } from './components/admin/clients/clients.component';
import { UsersComponent } from './components/admin/users/users.component';
import { PaymentComponent } from './components/admin/payment/payment.component';
import { SearchPaymentComponent } from './components/admin/search-payment/search-payment.component';
import { CompanyComponent } from './components/admin/company/company.component';

export const routes = [
    {
        path: '',
        redirectTo: '/company',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'change-types',
        component: ChangeTypesComponent
    },
    {
        path: 'dosages',
        component: DosagesComponent
    },
    {
        path: 'clients',
        component: ClientsComponent
    },
    {
        path: 'users',
        component: UsersComponent
    },
    {
        path: 'payment',
        component: PaymentComponent
    },
    {
        path: 'search-payment',
        component: SearchPaymentComponent
    },
    {
        path: 'company',
        component: CompanyComponent
    }
];
