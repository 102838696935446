import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '../../service/authorization.service';
import { UsersService } from 'src/app/service/users.service';
import { Router } from '@angular/router';
import { CompanyService } from 'src/app/service/company.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  authorizationByRole: any = false;
  public isAssociated: boolean;
  authorityAccount: any = false;
  isNavbarCollapsed: any = false;
  userName: any = '';
  getAccount: any;
  account: any;
  company: any;

  title = 'Mi Alquiler';
  isAuthenticated: boolean;
  isCollapsed = true;

  public user: any;

  constructor(
    private authorizationService: AuthorizationService,
    private userService: UsersService,
    private router: Router,
    private companyService: CompanyService
  ) {
    // this.currentUser();
    // console.log(localStorage.getItem('comp'));
    if (localStorage.getItem('comp') === null) {
      // this.router.navigate(['/login']);
      this.isNavbarCollapsed = true;
    }
    // this.getCompanyName();
    this.currentUser2();
  }

  ngOnInit() {
    $("#menu-toggle").click(function (e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
    });
  }

  getCompanyName() {
    this.companyService.getById(localStorage.getItem('comp')).subscribe(
      (res) => {
        this.company = res;
      }
    );
  }

  currentUser2() {
    // console.log('useeeer current ');
    this.userService.getCurrentUser().subscribe(
      (res) => {
        this.account = res;
        // console.log(this.account);
        this.userName = res.login;
      }
    );
  }
  currentUser() {
    if (this.authorizationService.getToken()) {
      this.getAccount = this.authorizationService.getAccount().subscribe(
        (res) => {
          this.account = res;
          this.isAssociated = this.account.codigoUnico ? true : false;
          this.authorityAccount = this.account.authorities.includes('ROLE_ADMIN') ||
            this.account.authorities.includes('ROLE_USER') ||
            this.account.authorities.includes('ROLE_EMPRESA') ||
            this.account.authorities.includes('ROLE_GESTOR') || this.account.authorities.includes('ROLE_CAJERO');
          // this.image = this.account;
          console.log('cuenta ' + this.account);
          const name = this.account.firstName; // email.substring(0, this.account.email.lastIndexOf("@"));
          this.userName = name ? name : this.account.ci;
        }
      );
    } else {
      this.account = null;
    }
  }

  signOut() {
    this.authorizationService.logout();
    this.router.navigate(['/login']);
    this.account = null;
    this.userName = '';
  }

  isLogged() {
    // console.log(this.account);
    if (this.account === null || this.account === undefined) {
      return false;
    } else {
      return true;
    }
    /*
    this.authorizationService.isLogged().subscribe(
      res => {
        console.log(res);
        this.authorizationByRole = res;
      }
    );*/
  }

  loginRedirect() {
    console.log('login');
    this.router.navigate(['/public/login']);
  }

  redirectLink(link) {
    this.router.navigate([link]);
  }
  register() {}
 
  logout() {
    //this._userService.logout();
  }

}
