import { Injectable } from '@angular/core';
import { WebService } from './web.service';
import { SERVER } from '../config/server.config';
import { map } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  constructor(
    private webService: WebService
  ) { }

  login(user) {
    // tslint:disable-next-line:max-line-length
    return this.webService.post(SERVER.AUTHENTICATE + '/login', user, this.webService.defaultOptions());
  }

  logout() {
    localStorage.removeItem('mfx-token');
    sessionStorage.removeItem('mfx-token');
  }

  getAccount() {
    return this.webService.get(SERVER.ACCOUNT, this.webService.JSONOptions(this.getToken()));
  }

  getToken() {
    // tslint:disable-next-line:max-line-length
    return sessionStorage.getItem('mfx-token') ? sessionStorage.getItem('mfx-token') : (localStorage.getItem('mfx-token') ? localStorage.getItem('mfx-token') : '');
  }

  isTokenExpired(token?: string): boolean {
    if (!token) {
      token = this.getToken();
    }
    if (!token) {
      return true;
    }
    const date = this.getTokenExpirationDate(token);
    if (date === undefined) {
      return false;
    }
    const expired = !(date.valueOf() > new Date().valueOf());
    if (expired) {
      this.logout();
    }
    return expired;
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);
    if (decoded['exp'] === undefined) {
      return null;
    }
    const date = new Date(0);
    date.setUTCSeconds(decoded['exp']);
    return date;
  }

  isLogged() {
    return this.webService.get(SERVER.AUTHENTICATE, this.webService.textOptions(this.getToken())).pipe(map(res => {
      console.log(res);
      console.log(this.isTokenExpired());
      const logged = res && !this.isTokenExpired();
      if (logged) {
        return true;
      } else {
        // this.router.navigate(['/']);
        return false;
      }
    }));
  }
}
