import { Component, Input, OnInit } from '@angular/core';
import { DateFunctionService } from 'src/app/service/date-function.service';
import { NumberFunctionService } from 'src/app/service/number-function.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  @Input() dataInvoice;
  @Input() image;
  invoice: any = {};
  montoLiteral = '';
  img: any;
  detalle = '';

  constructor(
    private dateFunctionService: DateFunctionService,
    private numberFunctionService: NumberFunctionService,
    public activeModal: NgbActiveModal
  ) {
  }

  ngOnInit() {
    console.log('invoice3', this.dataInvoice);
    console.log('image3',this.image);
    this.initValues();
    //this.generatePdf();
    //this.closeModal();
  }

  initValues() {
    //this.montoLiteral = this.numberFunctionService.NumerosaLetras(this.dataInvoice.amount);
  }

  numberToLetter(amount) {
    return this.numberFunctionService.NumerosaLetras(amount);
  }

  generatePdf() {
    var data = document.getElementById('contentToConvert');
    // console.log('DATAAAA', data);
    html2canvas(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf.jsPDF('p', 'mm', 'a4');
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('invoice.pdf');
    });
    /* var doc = new jspdf.jsPDF();
    html2canvas(data).then(canvas => {
      doc.addImage(canvas.toDataURL("image/png"), 'PNG', 15, 40, 180, 110);
      doc.save(`MYPdf.pdf`);
    }); */
    
  }

  convertirFechaATexto(fecha) {
    //console.log(fecha);
    // const options = { year: 'numeric', month: 'long', day: 'numeric' };
    // return fecha.toLocaleDateString('es-ES', options);
    const meses = [
      'Enero', 'Febrero', 'Marzo',
      'Abril', 'Mayo', 'Junio', 'Julio',
      'Agosto', 'Septiembre', 'Octubre',
      'Noviembre', 'Diciembre'
    ];
    const date2 = fecha.split('-');

    const date = new Date(date2[0], (date2[1] - 1), date2[2]);
    //console.log('fecha ' + date);
    const dia = date.getDate();
    const mes = date.getMonth();
    const ano = date.getFullYear();
    return `${dia} de ${meses[mes]} de ${ano}`;
  }

  roundTwoDigits(amount) {
    return amount.toFixed(2);
  }

  convertirFechaDDMMYYYY(fecha) {
    //console.log('fechaaaaaa', fecha);
    const date2 = fecha.split('-');
    return `${date2[2]}/${date2[1]}/${date2[0]}`;
  }

  closeModal() {
    this.activeModal.close();
  }
}
