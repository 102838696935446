import { Injectable } from '@angular/core';
import { WebService } from './web.service';
import { SERVER } from '../config/server.config';
import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root'
})
export class DosagesService {

  constructor(
    private webService: WebService,
    private authService: AuthorizationService
  ) { }

  getAll(page, size) {
    const URL = SERVER.DOSAGES + '/' + this.pageSize(page, size);
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.get(URL, headers);
  }

  pageSize(page, size): string {
    return '?page=' + page + '&size=' + size;
  }

  get(id: any) {
    const URL = SERVER.DOSAGES + '/' + id;
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.get(URL, headers);
  }

  create(dosage: any) {
    const URL = SERVER.DOSAGES + '/save';
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.post(URL, dosage, headers);
  }

  update(dosage: any) {
    const URL = SERVER.DOSAGES + '/update';
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.put(URL, dosage, headers);
  }

  deleteDosa(dosageId: any) {
    const URL = SERVER.DOSAGES + '/' + dosageId;
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.delete(URL, headers);
  }

  verifyDosage() {
    const URL = SERVER.DOSAGES + '/verify-dosage';
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.get(URL, headers);
  }
}
