import { Component, OnInit, TemplateRef } from '@angular/core';
import { UsersService } from 'src/app/service/users.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { RolService } from 'src/app/service/rol.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  public modalRef: BsModalRef;
  public submittedOption: any = false;
  submitted: any = false;

  listUsers = [];
  roles = [];
  user: any = {};
  userFormGroup: FormGroup;
  currentPage = 1;
  pageSize = 10;

  constructor(
    private userService: UsersService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private rolService: RolService
  ) {
    this.validateFormGroup();
  }

  ngOnInit() {
    this.getAllUsers();
    this.getAllRoles();
  }

  getAllRoles() {
    this.rolService.getAllRoles().subscribe(
      (res) => {
        console.log(res);
        this.roles = res;
      }
    );
  }

  validateFormGroup() {
    this.userFormGroup = this.formBuilder.group({
      id: [null],
      email: [null, [Validators.required]],
      login: [null, [Validators.required]],
      name: [null, [Validators.required]],
      roleId: [null, [Validators.required]],
      password: [null, [Validators.required]]
    });
  }

  getAllUsers() {
    this.userService.getAll(this.currentPage - 1, this.pageSize).subscribe(
      (res) => {
        console.log(res);
        this.listUsers = res;
      }
    );
  }

  get formValue() {
    return this.userFormGroup.value;
  }

  get formControls() {
    return this.userFormGroup.controls;
  }

  openModal(template: TemplateRef<any>, user?: any) {
    this.modalRef = this.modalService.show(template);
    if (user) {
      this.user = user;
    } else {}
  }

  onSubmit() {
    this.submitted = true;
    if (this.userFormGroup.invalid) {
      return;
    }

    if (this.formValue.id) {
      this.update();
    } else {
      if (!this.submittedOption) {
        this.submittedOption = true;
        // console.log("ingresando dosifica");

        this.create();
      }
    }
  }

  create() {
    console.log(this.userFormGroup.value);
    this.userService.create(this.userFormGroup.value).subscribe(
      (res) => {
        // console.log(res);
        this.getAllUsers();
        this.validateFormGroup();
        this.modalRef.hide();
      }
    );
  }

  delete() {
    console.log('borrando');
    this.userService.delete(this.user.id).subscribe(
      (res) => {
        this.getAllUsers();
        this.validateFormGroup();
        this.modalRef.hide();
      }
    );
  }

  update() {
    console.log(this.userFormGroup.value);
    this.userService.update(this.userFormGroup.value).subscribe(
      (res) => {
        this.getAllUsers();
        this.validateFormGroup();
        this.modalRef.hide();
      }
    );
  }

}
