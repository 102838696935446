import { environment } from '../../environments/environment';

export const SERVER = (function () {
    const URL = {
      BASE: environment.SERVER_URL // + '/api' // local
    };
    const AUTHENTICATE = 'auth';
    const ACCOUNT = 'account';
    const CLIENTS = 'clients';
    const DOSAGES = 'dosages';
    const USERS = 'users';
    const PAYMENT = 'payment';
    const COMPANY = 'company';
    const INVOICE = 'invoices';
    const PAYMENTDETAIL = 'paymentDetail';
    const ROLES = 'roles';
    return {
        URL_BASE: URL.BASE,
        URL_IMAGE: environment.SERVER_URL,
        AUTHENTICATE: `${URL.BASE}/${AUTHENTICATE}`,
        ACCOUNT: `${URL.BASE}/${ACCOUNT}`,
        CLIENTS: `${URL.BASE}/${CLIENTS}`,
        DOSAGES: `${URL.BASE}/${DOSAGES}`,
        USERS: `${URL.BASE}/${USERS}`,
        PAYMENT: `${URL.BASE}/${PAYMENT}`,
        COMPANY: `${URL.BASE}/${COMPANY}`,
        INVOICE: `${URL.BASE}/${INVOICE}`,
        PAYMENTDETAIL: `${URL.BASE}/${PAYMENTDETAIL}`,
        ROLES: `${URL.BASE}/${ROLES}`
    };
})();
