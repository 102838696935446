import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/service/company.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsersService } from 'src/app/service/users.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  companyFormGroup: FormGroup;
  company: any = {};
  submitted: any = false;
  account: any;

  constructor(
    private companyService: CompanyService,
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.validateFormGroup();
    this.getData();
    this.currentUser2();
  }

  validateFormGroup() {
    this.companyFormGroup = this.formBuilder.group({
      id: [null],
      name: ['', [Validators.required]],
      nit: ['', [Validators.required]],
      email: ['', [Validators.required]],
      telephone: ['', [Validators.required]],
      address: ['']
    });
  }

  get formValue() {
    return this.companyFormGroup.value;
  }

  get formControls() {
    return this.companyFormGroup.controls;
  }

  validateData() {
    if (this.company === null) {
    }
  }

  getData() {
    /*this.companyService.getById(localStorage.getItem('comp')).subscribe(
      (res) => {
        // console.log(res);
        this.company = res;
        this.companyFormGroup.patchValue(res);
      }
    );*/
    this.companyService.getData().subscribe(
      (res) => {
        // console.log(res);
        this.company = res;
        this.companyFormGroup.patchValue(res);
      }, (error) => {
        this.router.navigate(['/login']);
      }
    );
  }

  onSubmit() {
    if (this.companyFormGroup.invalid) {
      console.log('no valido');
      return;
    }
    this.update();
  }

  update() {
    this.companyService.update(this.companyFormGroup.value).subscribe(
      (res) => {
        this.company = res;
      }
    );
  }

  currentUser2() {
    // console.log('useeeer current ');
    this.userService.getCurrentUser().subscribe(
      (res) => {
        this.account = res;
      }, (error) => {
        this.router.navigate(['/login']);
      }
    );
  }

}
