import { Injectable } from '@angular/core';
import { WebService } from './web.service';
import { SERVER } from '../config/server.config';
import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private webService: WebService,
    private authService: AuthorizationService
  ) { }

  getData() {
    const URL = SERVER.COMPANY + '/getFirst';
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.get(URL, headers);
  }

  update(company: any) {
    const URL = SERVER.COMPANY + '/update';
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.put(URL, company, headers);
  }

  getCompanies() {
    const URL = SERVER.COMPANY + '/getAll';
    const headers = this.webService.JSONOptions(this.webService.defaultOptions());
    return this.webService.get(URL, this.webService.defaultOptions());
  }

  getAll(page, size) {
    const URL = SERVER.COMPANY + '/get' + this.pageSize(page, size);
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.get(URL, headers);
  }

  pageSize(page, size): string {
    return '?page=' + page + '&size=' + size;
  }

  getById(compId: any) {
    const URL = SERVER.COMPANY + '/' + compId;
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.get(URL, headers);
  }
}
