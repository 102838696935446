import { Injectable } from '@angular/core';
import { DateFunctionService } from 'src/app/service/date-function.service';
import { NumberFunctionService } from 'src/app/service/number-function.service';

@Injectable({
  providedIn: 'root'
})
export class PrintFunctionService {

  constructor(
    private dateFunctionService: DateFunctionService,
    private numberFunctionService: NumberFunctionService
  ) { }

  roundTwoDigits(num: any) {
    return num.toFixed(2);
  }

  printInvoice2(invoice, img): void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    const data = ''; // this.pagarSolvenciaProfesionalFormGroup.value;
    const date: Date = new Date();
    const montoLiteral = this.numberFunctionService.NumerosaLetras(invoice.amount); // this.NumerosaLetras(invoice.montoTotal);
    // console.log(invoice);
    let detalle = '';
    for (const detail of invoice.paymentDetailDTOList) {
      detalle += `
        <tr>
          <td class="align-center">${detail.quantity}</td>
          <td>${detail.description}</td>
          <td class="align-right">${this.roundTwoDigits(detail.amount)}</td>
        </tr>
      `;
    }
    // console.log(invoice.name);
    popupWin.document.write(`
    <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <style media="all">
    @page {
        size: auto;   /* auto is the initial value */
        margin: 0;  /* this affects the margin in the printer settings */
    }
    * {
        margin: 0;
        padding: 0;
    }
    body {
        margin-top: 1cm;
        background: #FFF;
        color: #000;
        font-family: Arial, Helvetica, sans-serif;
    }
    /* css grid */

    .contenedor {
        display: grid;
        min-width: 21cm;
        padding: 0.5cm;
        border: 2px solid transparent;
        margin: auto;
        font-size: 12px;
        text-align: center;
        
        grid-template-columns: 99%;
/*      grid-template-rows: auto ;*/
        grid-gap: 10px;
                            
    }   
    .contenedor > div {
        color: #000;
        /* background: lightgray; */
    }
         
    .contenedor .head {
        grid-column-start: 1;
        grid-column-end: 3;
        /* grid-template-rows: 2cm 2cm; */
        /* background: lightgreen; */
        grid-template-areas:    "head head01"
                                "head head02";
    }
    .head {
        display: grid;
        grid-template-columns: 11cm auto ;
        grid-gap: 5px;
       
    }
    .head .head02 {
        grid-area: head02;
    }
    .head > div {
        background: transparent;
    }
    .contenedor .title {
        /* background: lightgreen; */
        grid-column-start: 1;
        grid-column-end: 3;
    }
    .align-center {
        text-align: center;
    }
    .align-right {
        text-align: right;
    }
    
    .contenedor .name {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-template-rows: 1cm;
        /* background: lightgreen; */
    }
    .name {
        display: grid;
        grid-template-columns: 12cm auto;
        grid-gap: 5px;
        text-align: right;
    }
    .name > div {
        background: transparent;
    }
    .name02 {
        display: grid;
        grid-template-columns: 2.2cm auto;
        //grid-template-columns: 3cm auto;
        grid-gap: 5px;
        text-align: right;
    }
    .name02 p {
        text-align: left;
    }
    .name02 > div {
        background: transparent;
    }
    
    .contenedor .cont {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-template-rows: auto;
        /* background: lightgreen; */
    }
    .cont {
        display: grid;
        grid-template-columns: auto;
        grid-gap: 5px;
    }
    .cont > div {
        background: transparent;
    }

    .contenedor .dat {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-template-rows: 1.2cm 1.2cm;
        /* background: lightgreen; */
        grid-template-areas:    "dat datqr"
                                "dat datqr";
    }
    .dat {
        display: grid;
        grid-template-columns: 16.7cm auto ;
        grid-gap: 5px;
        text-align: end;
    }
    .dat img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 110px;
    }
    .dat .datqr {
        grid-area: datqr;
    }
    .dat > div {
        background: transparent;
    }
    
    .contenedor .foot {
        /* background: lightgreen; */
        grid-column-start: 1;
        grid-column-end: 3;
        margin-top: 0.4cm;
    }

/* estilos datos */
    .nit01 {
        padding: 0.1cm 0.1cm;
        text-align: end;
        border: 2px solid #000;
        border-radius: 0.2cm;
    }
    .names01 {
        padding-top: 0.2cm;
        padding-right: 3px;
    }
    .names02 {
        border: 2px solid #000;
        padding-top: 0.35cm;
    }
    span {
        font-size: 9pt;
        font-weight: 600;
    }
    /* estilos tabla */
    table {
        width: 100%;
        border: 2px solid #000;
        border-collapse: collapse;
    }

    table, th, td {
        border-right: 2px solid #000;
    }

    thead th:nth-child(1) {
        width: 4cm;
    }

    thead th:nth-child(2) {
        width: auto;
    }

    thead th:nth-child(3) {
        width: 3.5cm;
    }

    thead {
        height: 0.6cm;
        //color: #FFF;
        color: #000;
        background: #000;
    }
    tbody, tr, td {
        padding: 0.2cm;
        text-align: left;
    }
    thead, tr, th {
        text-align: center;
    }
    input {
        border: transparent;
    }
    .header-img {
        display: flex;
    }
    .titulo01 {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15pt;
        font-weight: bold;
    }
    .padding01 {
        padding: 0 20px;
    }
    .border-names {
        line-height: 0.8cm;
        border: 2px solid #000;
    }
    </style>

    <title>FACTURACIÓN</title>
</head>
<body>
<div class="contenedor">
    <div class="head">
        <div class="header-img">
            <img src="assets/logo-litec.png" style="height: 95px; width: 55%" alt="">
            <div class="padding01">
                <h4>Casa Matriz</h4>
                ${invoice.address}<br>
                Tel: ${invoice.telephone}<br>
                LA PAZ - BOLIVIA
            </div>
        </div>
        <div class="nit01">
            <span>NIT:</span><input type="text" class="align-right" value="${invoice.nitCompany}"><br>
            <span>N° de Factura:</span><input type="text" class="align-right" value="${invoice.invoiceNumber}"><br>
            <span>N° de Autorización:</span><input type="text" class="align-right" value="${invoice.dosageDTO.authorizationNumber}">
        </div>
        <div class="titulo01">
            FACTURA
        </div>
        <div class="head02">
            <h3>ORIGINAL</h3>
            Mantenimiento y Reparación de Máquinas de Oficina e Informática
        </div>
    </div>
    <div class="border-names">
        <div class="name">
            <div class="names01">
                <span>Lugar y Fecha: </span><input type="text" style="width:9cm;" value="La Paz, ${this.dateFunctionService.convertirFechaATexto(invoice.emissionDate)}"><br>
            </div>
            <div class="names01">
                <span>NIT/CI: </span><input type="text" value=${invoice.nit}><br>
            </div>
        </div>
        <div class="name02">
            <span>Señor(es):</span><p>${invoice.name}</p>
        </div>
    </div>
    <div class="cont">
        <div>
            <table>
                <thead>
                <tr>
                    <th>Cantidad</th>
                    <th>Detalle</th>
                    <th>Monto</th>
                </tr>
                </thead>
                <tbody>
                ${detalle}
                </tbody>
            </table>
            <table style="margin-top:0.1cm;">
                <tr>
                    <td>SON: ${montoLiteral} BOLIVIANOS</td>
                    <td style="width:2.5cm; background:#000;">TOTAL Bs</td>
                    <td style="width: 3.1cm;" class="align-right">${this.roundTwoDigits(invoice.amount)}</td>
                </tr>
            </table>
        </div>
    
    </div>
    <div class="dat">
        <div class="names02">
            <span>Código de Control: </span><input type="text" style="width:13cm;" value="${invoice.controlCode}">
        </div>
        <div class="names02">
            <span>Fecha Límite de Emisión: </span><input type="text" style="width: 12cm;" value="${this.dateFunctionService.convertirFechaDDMMYYYY(invoice.dosageDTO.emissionDeadline)}">
        </div>
        <div class="datqr">
            <img src="${img}" alt="">
        </div>
    </div>
    <div class="foot">
        <h4>“ESTA FACTURA CONTRIBUYE AL DESARROLLO DEL PAÍS. EL USO ILÍCITO DE ÉSTA SERÁ SANCIONADO DE ACUERDO A LEY”</h4>
        <h5>Ley N°453: El proveedor de servicios debe habilitar medios e instrumentos para efectuar consultas y reclamaciones.</h5>
    </div>

    </div>
</body>
<body onload="window.print();window.close()">${printContents}</body>
</html>
    `);
    popupWin.document.close();
  }

}
