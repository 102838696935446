import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { PaymentService } from 'src/app/service/payment.service';
import { ClientsService } from 'src/app/service/clients.service';
import { InvoicesService } from 'src/app/service/invoices.service';
import swal from 'sweetalert2';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable, of } from 'rxjs';
import { mergeMap, filter } from 'rxjs/operators';
import { PrintFunctionService } from 'src/app/service/print-function.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DosagesService } from 'src/app/service/dosages.service';
import { CompanyService } from 'src/app/service/company.service';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InvoiceComponent } from '../invoice/invoice.component';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  public modalRef: BsModalRef;

  documentType: any;
  payments: any = [];
  payment: FormGroup;
  company: any = {};
  invoiceForm: FormGroup;
  totalAmount: any = 0;
  nit: any;
  public submitted: any = false;
  public submittedOption: any = false;

  typeaheadLoading: boolean;
  dataSource: Observable<any>;
  userKeyword: any = [];
  imageToShow: any;
  isImageLoading: any;
  isDosageAvailable: any = false;
  message = '';

  constructor(
    private formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private clientService: ClientsService,
    private modalService: BsModalService,
    private invoicesService: InvoicesService,
    private printFunctionService: PrintFunctionService,
    private dosageService: DosagesService,
    private companyService: CompanyService,
    private http: HttpClient,
    private modalService1: NgbModal
  ) {
    this.reCharge();
    this.getCompany();
  }

  ngOnInit() {
    this.paymentFormGroupValidate();
    this.invoiceFormGroupValidate();
    this.verifyDosageAvailable();
  }

  getCompany() {
    this.companyService.getData().subscribe(
      (res) => {
        this.company = res;
      },
      (error) => {
        console.log('error');
      }
    );
  }

  invoiceFormGroupValidate() {
    this.invoiceForm = this.formBuilder.group({
      clientId: [1],
      name: ['', [Validators.required]],
      nit: ['', [Validators.required]]
    });
  }

  verifyDosageAvailable() {
    // console.log('verifica');
    this.dosageService.verifyDosage().subscribe(
      (res) => {
        if (res.available) {
          this.isDosageAvailable = true;
        } else {
          this.isDosageAvailable = false;
          this.message = res.message;
        }
        // console.log(res);
      }
    );
  }

  paymentFormGroupValidate() {
    this.payment = this.formBuilder.group({
      quantity: ['', [Validators.required]],
      price: ['', [Validators.required]],
      amount: [''],
      description: ['', [Validators.required]],
    });
  }

  addPayment() {
    this.payment.value.amount = (this.payment.value.quantity * this.payment.value.price);
    // this.payment.value.razonSocial = ' ';
    if (this.payment.value.quantity > 0) {
      // console.log(this.payment.value);
      this.payments.push(this.payment.value);

      this.totalAmount += this.payment.value.amount;
      // console.log(this.payments);
      this.paymentFormGroupValidate();
    }
    // console.log(this.payments);
  }

  get formValuePayment() {
    return this.payment.value;
  }

  get formControlsPayment() {
    return this.payment.controls;
  }

  get formValueInvoice() {
    return this.invoiceForm.value;
  }

  get formControlsInvoice() {
    return this.invoiceForm.controls;
  }

  reset() {
    this.invoiceFormGroupValidate();
    this.paymentFormGroupValidate();
    this.payments = [];
    this.totalAmount = 0;
    this.nit = '';
  }

  onSubmitted() {
    if (!this.isDosageAvailable) {
      this.message = 'No existe dosificación disponible';
      return;
    }

    this.submitted = true;
    if (this.invoiceForm.invalid || this.payments.length <= 0) {
      console.log('error');
      return;
    } else {
      if (this.invoiceForm.value.nit === null || this.invoiceForm.value.nit === '') {
        this.message = 'Tiene que seleccionar a un cliente';
        return;
      }
      if (!this.submittedOption) {
        this.submittedOption = true;
        this.payInvoice();
      }
    }
  }

  payInvoice() {
    const payData = {
      name: this.invoiceForm.value.name,
      nit: this.invoiceForm.value.nit,
      amount: this.totalAmount,
      clientId: this.invoiceForm.value.clientId
    };
    const data = {
      payment: payData,
      detail: this.payments
    };
    this.paymentService.createPayment(data).subscribe(
      (res) => {
        this.message = '';
        this.getQRCode2(res);
        this.reset();
        this.modalRef.hide();
      }
    );
    this.submittedOption = false;
    // this.showNotification('Usuario no encontrado', 4000, 'success');
  }

  delete(index: any, payment: any) {
    this.payments.splice(index, 1);
    this.totalAmount = this.totalAmount - payment.amount;
  }

  search() {
    if (this.nit !== null && this.nit.length > 0) {
      this.clientService.searchVM(this.nit).subscribe(
        (res) => {
          this.userKeyword = res;
          this.reCharge();
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.invoiceForm.patchValue({
      clientId: e.item.id,
      name: e.item.fullName,
      nit: e.item.nit
    });
    this.userKeyword = [];
  }

  reCharge() {
    this.dataSource = Observable.create((observer: any) => {
      observer.next(this.nit);
    }).pipe(
      mergeMap((token: string) => this.getStatesAsObservable(token))
    );
  }

  getStatesAsObservable(token: string): Observable<any> {

    const query = new RegExp(token, 'i');

    return of(
      this.userKeyword.filter((client: any) => {
        return query.test(client.ciFullName);
      })
    );
  }

  createImageFromBlob(invoice: any, image: Blob) {
    this.printFunctionService.printInvoice2(invoice, null);
    // const reader = new FileReader();
    // reader.addEventListener('load', () => {
    //   this.imageToShow = reader.result;
    //   console.log(invoice);
    //   console.log(reader.result);
    //   this.printFunctionService.printInvoice(invoice, reader.result);
    // }, false);
    // if (image) {
    //   reader.readAsDataURL(image);
    // }
  }

  createImageFromBlob2(invoice: any, image: Blob) {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      this.imageToShow = reader.result;
      invoice.address = this.company.address;
      invoice.nitCompany = this.company.nit;
      invoice.telephone = this.company.telephone;
      this.printFunctionService.printInvoice2(invoice, reader.result);
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  createImageFromBlob3(invoice: any, image: Blob) {
    const modalRef1 = this.modalService1.open(InvoiceComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      windowClass: 'custom-class'
    });
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      this.imageToShow = reader.result;
      invoice.address = this.company.address;
      invoice.nitCompany = this.company.nit;
      invoice.telephone = this.company.telephone;
      //this.printFunctionService.printInvoice2(invoice, reader.result);
      modalRef1.componentInstance.dataInvoice = invoice;
      modalRef1.componentInstance.image = reader.result;
      modalRef1.result.then((result) => {
        console.log('result', result);
      }, (reason) => { });
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getQRCode2(invoice: any) {
    this.isImageLoading = true;
    this.invoicesService.getQRCodeByInvoice(invoice).subscribe(
      (res) => {
        // this.readyToPrint = true;
        // this.swPrintInvoice = true;
        // console.log(res);
        this.createImageFromBlob3(invoice, res);
        this.isImageLoading = false;
      }, error => {
        console.log(error);
        this.isImageLoading = false;
      });
  }

  openModal(template: TemplateRef<any>) {
    // this.dosage = dosificacion;
    this.modalRef = this.modalService.show(template);
  }

  getQRCode(invoice: any) {
    this.createImageFromBlob(invoice, null);
    // invoice.dosageId = invoice.dosageId.id;
    // this.isImageLoading = true;
    // this.invoicesService.getQRCodeByInvoice(invoice).subscribe(
    //   (res) => {
    //     // this.readyToPrint = true;
    //     // this.swPrintInvoice = true;
    //     // console.log(res);
    //     this.createImageFromBlob(invoice, res);
    //     this.isImageLoading = false;
    //   }, error => {
    //     console.log(error);
    //     this.isImageLoading = false;
    //   });
  }
}
