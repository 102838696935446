import { Component, OnInit, TemplateRef } from '@angular/core';
import { PaymentService } from 'src/app/service/payment.service';
import { PaymentDetailService } from 'src/app/service/payment-detail.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InvoicesService } from 'src/app/service/invoices.service';
import { PrintFunctionService } from 'src/app/service/print-function.service';
import { CompanyService } from 'src/app/service/company.service';
//import { ModalService } from 'src/app/service/modal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InvoiceComponent } from '../invoice/invoice.component';

@Component({
  selector: 'app-search-payment',
  templateUrl: './search-payment.component.html',
  styleUrls: ['./search-payment.component.scss']
})
export class SearchPaymentComponent implements OnInit {

  keyword: any = '';
  startDate: any = null;
  endDate: any = null;
  state = 'COMPLETE';
  currentPage = 1;
  pageSize = 10;
  totalItems = 0;
  rotate = true;
  maxSize = 10;
  paymentId = null;
  imageToShow: any;

  payments = [];
  payment: any = null;
  details = [];
  public modalRef: BsModalRef;
  paymentFormGroup: FormGroup;
  isImageLoading: any;
  company: any = {};
  public submittedOption: any = false;

  constructor(
    private paymentService: PaymentService,
    private modalService: BsModalService,
    private invoicesService: InvoicesService,
    private paymentDetailService: PaymentDetailService,
    private printFunctionService: PrintFunctionService,
    private companyService: CompanyService,
    private formBuilder: FormBuilder,
    //private modalService1: ModalService
    private modalService1: NgbModal
  ) { }

  ngOnInit() {
    this.search();
    this.validateFormGroup();
    this.getCompany();
  }

  search() {
    this.paymentService.search(this.keyword, this.startDate, this.endDate, this.state,
      this.currentPage - 1, this.pageSize).subscribe(
        (res) => {
          console.log("my res", res);
          this.payments = res.content;
          this.totalItems = res.totalElements;
        }
      );
  }

  exportar() {
    console.log('exportando');
    this.paymentService.export(this.keyword, this.startDate, this.endDate, this.state)
      .subscribe((res) => {
        var csvData = this.ConvertToCSV(res);
        var blob = new Blob([csvData], { type: 'text/csv' });
        var url = window.URL.createObjectURL(blob);

        var a = document.createElement("a");
        a.href = url;
        a.download = 'pagos.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })

  }

  ConvertToCSV(objArray: any): string {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";

    for (var index in objArray[0]) {
      row += index + ';';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';

    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ';'

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }

  getCompany() {
    this.companyService.getData().subscribe(
      (res) => {
        //console.log(res);
        this.company = res;
      },
      (error) => {
        console.log('error');
      }
    );
  }

  validateFormGroup() {
    this.paymentFormGroup = this.formBuilder.group({
      id: [null],
      observation: [null, [Validators.required]]
    });
  }

  viewDetail(template: TemplateRef<any>, paymentId: any) {
    console.log(paymentId);
    this.modalRef = this.modalService.show(template);
    this.paymentDetailService.getDetailByPaymentId(paymentId).subscribe(
      (res) => {
        //console.log(res);
        this.details = res;
      }
    );
  }

  openCancelModal(template: TemplateRef<any>, payment: any) {
    this.paymentId = payment.id;
    this.payment = payment;
    this.modalRef = this.modalService.show(template);
  }

  cancelPayment() {
    if (this.paymentFormGroup.invalid) {
      console.log('error');
      return;
    }
    const data = {
      id: this.paymentId,
      observation: this.paymentFormGroup.value.observation
    };

    if (!this.submittedOption) {
      this.submittedOption = true;
      //console.log(data);
      this.paymentService.cancelPayment(data).subscribe(
        (res) => {
          this.modalRef.hide();
          this.validateFormGroup();
          this.search();
          this.submittedOption = false;
        }
      );
    }
  }

  closeModal() {
    //console.log('cerrar');
    this.paymentFormGroup.value.observation = null;
    this.modalRef.hide();
  }

  changeState() {
    console.log(this.state);
    this.search();
  }

  showState(state) {
    if (state === 'COMPLETE') {
      return 'Completado';
    }
    if (state === 'CANCEL') {
      return 'Anulado';
    }
  }

  changePage(event): void {
    this.currentPage = event.page;
    this.search();
  }

  reprint(paymentId: any) {
    this.paymentService.getInvoiceByPaymentId(paymentId).subscribe(
      (res) => {
        //console.log(res);
        this.getQRCode2(res);
      }
    );
  }
  reprintWeb(paymentId: any) {
    this.paymentService.getInvoiceByPaymentId(paymentId).subscribe(
      (res) => {
          console.log("mydat",res);
        this.getQRCode3(res);
      }
    );
  }

  getQRCode2(invoice: any) {
    this.isImageLoading = true;
    this.invoicesService.getQRCodeByInvoice(invoice).subscribe(
      (res) => {
        // this.readyToPrint = true;
        // this.swPrintInvoice = true;
        //console.log('QR', res);
        //console.log('INVOICE', invoice);
        //this.createImageFromBlob2(invoice, res);
        this.createImageFromBlob3(invoice, res);
        this.isImageLoading = false;
      }, error => {
        console.log(error);
        this.isImageLoading = false;
      });
  }

  getQRCode3(invoice: any) {
    this.isImageLoading = true;
    this.invoicesService.getQRCodeByInvoice(invoice).subscribe(
      (res) => {
        // this.readyToPrint = true;
        // this.swPrintInvoice = true;
        console.log('QR', res);
        console.log('INVOICE', invoice);
        this.createImageFromBlob2(invoice, res);
        //this.createImageFromBlob3(invoice, res);
        this.isImageLoading = false;
      }, error => {
        console.log(error);
        this.isImageLoading = false;
      });
  }

  createImageFromBlob3(invoice: any, image: Blob) {
    const modalRef1 = this.modalService1.open(InvoiceComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      windowClass: 'custom-class'
    });
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      this.imageToShow = reader.result;
      invoice.address = this.company.address;
      invoice.nitCompany = this.company.nit;
      invoice.telephone = this.company.telephone;
      //this.printFunctionService.printInvoice2(invoice, reader.result);
      modalRef1.componentInstance.dataInvoice = invoice;
      modalRef1.componentInstance.image = reader.result;
      modalRef1.result.then((result) => {
        console.log('result', result);
      }, (reason) => { });
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }
  
  createImageFromBlob2(invoice: any, image: Blob) {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      this.imageToShow = reader.result;
      invoice.address = this.company.address;
      invoice.nitCompany = this.company.nit;
      invoice.telephone = this.company.telephone;
      this.printFunctionService.printInvoice2(invoice, reader.result);
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  // getDetailByPaymentId(paymente) {}

}
