import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthorizationService } from 'src/app/service/authorization.service';
import { UsersService } from 'src/app/service/users.service';
import { CompanyService } from 'src/app/service/company.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  userFormGroup: FormGroup;
  companies: [];

  constructor(
    private formBuilder: FormBuilder,
    private authorizationService: AuthorizationService,
    private userService: UsersService,
    private router: Router,
    private route: ActivatedRoute,
    private companyService: CompanyService
  ) {
    this.validateFormGroup();
  }

  ngOnInit() {
    this.companyService.getCompanies().subscribe(
      (res) => {
        this.companies = res;
        // console.log(res);
      }
    );
  }

  validateFormGroup() {
    this.userFormGroup = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
      companyId: ['', [Validators.required]],
      rememberMe: true
    });
  }

  get formValue() {
    return this.userFormGroup.value;
  }

  get formControls() {
    return this.userFormGroup.controls;
  }

  onSubmit() {
    // console.log('login');
    // console.log(this.userFormGroup.value);
    this.login();
  }

  login() {
    this.authorizationService.login(this.formValue).subscribe(
      (res) => {
        // console.log(res);
        this.storage(res.token);
        const url = ['/clients'];
        // , { outlets: { sidebar: ['cursos'] }}
        this.router.navigate(url);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  storage(token) {
    if (this.formValue.rememberMe) {
      // console.log('local');
      localStorage.setItem('mfx-token', token);
      // localStorage.setItem('comp', this.userFormGroup.value.companyId);
    } else {
      // console.log('session');
      sessionStorage.setItem('mfx-token', token);
    }
  }

}
