import { Injectable } from '@angular/core';
import { WebService } from './web.service';
import { SERVER } from '../config/server.config';
import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  constructor(
    private webService: WebService,
    private authService: AuthorizationService
  ) { }

  getQRCodeByInvoice(invoice: any) {
    const URL = SERVER.INVOICE + '/get-qr-code';
    const headers = this.webService.JSONOptions(this.authService.getToken());
    return this.webService.post(URL, invoice, this.webService.imageFileHeaders(this.authService.getToken())); // , headers);
  }
}
