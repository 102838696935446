import { Injectable } from '@angular/core';
import { WebService } from 'src/app/service/web.service';
import { SERVER } from '../config/server.config';
import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(
    private webservice: WebService,
    private authService: AuthorizationService
  ) { }

  getAll(page, size) {
    const URL = SERVER.CLIENTS + '/' + this.pageSize(page, size);
    // console.log(this.authService.getToken());
    const headers = this.webservice.JSONOptions(this.authService.getToken());
    return this.webservice.get(URL, headers);
  }

  pageSize(page, size): string {
    return '?page=' + page + '&size=' + size;
  }

  get(id: any) {
    const URL = SERVER.CLIENTS + '/' + id;
    const headers = this.webservice.JSONOptions(this.authService.getToken());
    return this.webservice.get(URL, headers);
  }

  create(client: any) {
    const URL = SERVER.CLIENTS + '/save';
    const headers = this.webservice.JSONOptions(this.authService.getToken());
    return this.webservice.post(URL, client, headers);
  }

  update(client: any) {
    const URL = SERVER.CLIENTS + '/update';
    const headers = this.webservice.JSONOptions(this.authService.getToken());
    return this.webservice.put(URL, client, headers);
  }

  searchVM(keyword: any) {
    const URL = SERVER.CLIENTS + '/search/VM/' + keyword;
    const headers = this.webservice.JSONOptions(this.authService.getToken());
    return this.webservice.get(URL, headers);
  }

  delete(id) {
    const URL = SERVER.CLIENTS + '/' + id;
    const headers = this.webservice.JSONOptions(this.authService.getToken());
    return this.webservice.delete(URL, headers);
  }

  search(keyword: any, page, size) {
    const URL = SERVER.CLIENTS + '/search/' + keyword + this.pageSize(page, size);
    const headers = this.webservice.JSONOptions(this.authService.getToken());
    return this.webservice.get(URL, headers);
  }
}
