import { Component, OnInit, TemplateRef } from '@angular/core';
import { ClientsService } from 'src/app/service/clients.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  currentPage = 1;
  pageSize = 100;
  totalItems = 0;
  rotate = true;
  maxSize = 10;
  public modalRef: BsModalRef;
  clientFormGroup: FormGroup;
  submittedOption: any = false;
  submitted: any = false;
  keyword: any = '';
  client: any = null;

  clients: [];

  constructor(
    private modalService: BsModalService,
    private clientService: ClientsService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    // this.getAllClients();
    this.search();
    this.validateFormGroup();
  }

  getAllClients() {
    this.clientService.getAll(this.currentPage - 1, this.pageSize).subscribe(
      (res) => {
        this.clients = res;
        //console.log(res);
      }
    );
  }

  validateFormGroup() {
    this.clientFormGroup = this.formBuilder.group({
      id: [null],
      // firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: [''],
      nit: ['', [Validators.required]],
      cellphone: [''],
      address: ['']
    });
  }

  get formValue() {
    return this.clientFormGroup.value;
  }

  get formControls() {
    return this.clientFormGroup.controls;
  }

  create() {
    console.log('nuevo');
    this.clientService.create(this.clientFormGroup.value).subscribe(
      (res) => {
        this.validateFormGroup();
        this.modalRef.hide();
        // this.getAllClients();
        this.search();
      }
    );
  }

  update() {
    this.clientService.update(this.clientFormGroup.value).subscribe(
      (res) => {
        this.validateFormGroup();
        this.modalRef.hide();
        // this.getAllClients();
        this.search();
      }
    );
  }

  openModal(template: TemplateRef<any>, client?: any) {
    this.modalRef = this.modalService.show(template);
    if (client) {
      this.submitted = false;
      this.submittedOption = false;
      this.clientFormGroup.patchValue(client);
    } else {
      this.submitted = false;
      this.submittedOption = false;
      this.validateFormGroup();
    }
  }

  openModalDelete(template: TemplateRef<any>, client?: any) {
    this.modalRef = this.modalService.show(template);
    this.client = client;
    console.log(client);
  }

  onSubmit() {
    this.submitted = true;
    if (this.clientFormGroup.invalid) {
      return;
    }

    if (this.formValue.id) {
      this.update();
    } else {
      if (!this.submittedOption) {
        this.submittedOption = true;
        // console.log("ingresando dosifica");

        this.create();
      }
    }
  }

  delete() {
    this.clientService.delete(this.client.id).subscribe(
      (res) => {
        this.modalRef.hide();
        this.search();
      }
    );
  }

  search() {
    // console.log(this.keyword);
    if (this.keyword === '') {
      this.getAllClients();
    } else {
      this.clientService.search(this.keyword, this.currentPage - 1, this.pageSize).subscribe(
        (res) => {
          console.log(res);
          this.clients = res.content;
          this.totalItems = res.totalElements;
        }
      );
    }
  }

  changePage(event): void {
    this.currentPage = event.page;
    this.search();
  }
}
